import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import Home from "./Home/Home";
import Grades from "./Grades/Grades";

function App() {
  return (
      <Router>
        <header className="App-header"/>
        <div className="App">

          <Route exact path="/grades" component={Grades} />
          <Route exact path="/" component={Home} />
        </div>
      </Router>
  );
}

export default App;
